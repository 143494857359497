import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {BookingService,HomeService, DataService,MixpanelService } from '../services/index'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { formatDate   } from '@angular/common';
import * as $ from 'jquery';
import { PassDestinationValueService } from '../services/pass-destination-value.service';
import { PassDepatureValueService } from '../services/pass-depature-value.service';
import { ScrolltopService } from '../services/scrolltop.service';
import * as moment from 'moment';
import { Base64 } from 'js-base64';
import { EMPTY, fromEvent,  } from 'rxjs';
import { PixelService } from '../pixel.service';
import moengage from "@moengage/web-sdk";


@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css']
})
export class BookingComponent implements OnInit {
  @ViewChild('focustarget') focusEl: ElementRef;

  reactiveForm: UntypedFormGroup;reactiveMobileForm: UntypedFormGroup;pickupForm: UntypedFormGroup;
  fromPlaceholder="From";
  toPlaceholder="To";
  public pickupplaceholder: string = 'Boarding Point';
  public dropoffplaceholder: string = 'Dropoff Point';
  public keyword = 'name';
  checkiftoday:boolean;
  public pickplaceholder: string = 'Enter Pick Up';
  public destplaceholder: string = 'Enter Drop Off';
  public historyHeading: string = 'Recently selected';
  // pickupanddropoff
  pickuplocation:string =""
  dropofflocation:string =""
  pickupreturnlocation:string =""
  dropoffreturnlocation:string =""
  today=new Date();
  isOpen=false;
  bsValue;
  bsInlineValue;
  destinationLoading=false;
  beforeyesterday:Date =( d => new Date(d.setDate(d.getDate()-2)) )(new Date);
  yesterday:Date = ( d => new Date(d.setDate(d.getDate()-1)) )(new Date);
  tomorrow:Date = ( d => new Date(d.setDate(d.getDate()+1)) )(new Date);
  aftertomorrow:Date = ( d => new Date(d.setDate(d.getDate()+2)) )(new Date);
  //traveldate:any;
  data = [];formdata={};
  formdataTravel:any={};
  formdataReturn={};
  private sub: any;
  res: any = {};
  insurance: any = {};
  insurance_operators:any;
  return_schedule:any;
  schedule: any ={};
  routename: any ={};
  pickup:any =[];
  dropoff:any =[];
  dummyDropoff:any =[];
  seatsSelected: any=[];
  selectedOptionPassengers;
  fleet: any ={};
  seat_price: any ={};
  params:any;
  bookingdetails: any;
  results: any ;
  isResultsLoading=false;
  seatErrors=false;
  showSeats=false;
  changeI=false;
  pickUpInit: string;
  dropOffInit: string;
  returnPickUpInit: string;
  returnDropOffInit: string;
  travelDateInit: string;
  returnDateInit:string;
  isUnavailabe=false;
  dataLoading=false;
  isPickupClicked=false;
  isReturnTicket=false;
  isReturnTicketSelected=false;
  isShowingFilter=false;
  totalFare=0;
  fareCurrency='Ksh'
  returning=false;
  submitted=false;
  submittedMashPoa:boolean=false;
  selectedIndex = -1;
  hide_from_ui:number;
  availableSeats=[];
  seats =[];
  seatData:any =[];
  seatMobile:any =[];
  seatDataNew:any =[];
  seatRes:any;
  IsDiscount=false;
  discountAmount:any;
  busCheckSelected:any;
  isReadOnly=false;
  types= [];
  sticky: boolean = false;
  elementPosition: any;
  busSelected:any;
  isBusSelected:boolean =false;
  busOperatorSelected:any;
  selectedBus:any;
  currentBusId:any;
  selectedBusStatus:boolean=false;
  isAcClicked=false;
  isEmptyReturn=false;
  isWater=false;
  finalTravelDate: string;
  finalReturnDateInit:string;
  hide_from_ui_shuttle:number;
  booked_seat_session_id='';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service:BookingService,
    private homeservice:HomeService,
    private dataService:DataService,
    private _fb: UntypedFormBuilder,
    private serviceDestination: PassDestinationValueService,
    private serviceDepature:PassDepatureValueService,
    private scrolltopService:ScrolltopService,
    private mixpanelService: MixpanelService,

  ) {
  
    this.reactiveForm = _fb.group({
      pickup:['', Validators.required],
      dropoff:['', Validators.required],
      traveldate:['', Validators.required],
      returndate:[new Date(), Validators.required]
    });
    this.reactiveMobileForm = _fb.group({
      pickup:['', Validators.required],
      dropoff:['', Validators.required],
      traveldate:[new Date(), Validators.required]
    });
     // pickupanddropoff
    this.pickupForm = _fb.group({
      pickupLocation:['', Validators.required],
      dropoffLocation:['', Validators.required],
    });
    
   }
   returnClicked(){
    this.returning=!this.returning;
    this.isOpen=true;
  }
  closeReturn = () =>{
    this.returning=false;
  }
  showFilter () {
    this.isShowingFilter=true;
  }
  hideFilter() {
    this.isShowingFilter=false;
  }
  ngOnInit() {
    this.router.events.subscribe((evt) => {
             if (!(evt instanceof NavigationEnd)) {
                 return;
             }
             window.scrollTo(0, 0)
         });
    this.loadDestinations();
    this.dataService.returnSearchValueChange.subscribe(message =>{
      if(!message){
        this.route.queryParams.subscribe(params => {
          let m = params['koroga-fest'];
          if(m){
            message=m;
          }else{
            message=localStorage.getItem('search-buupass');
          }

      });
        
        if(!message){
          this.router.navigate(['/index.html'])
        }
      }


      localStorage.setItem('search', message);
      this.bookingdetails = JSON.parse(Base64.decode(message))
      this.pickUpInit=this.bookingdetails.pickupname;
      this.dropOffInit=this.bookingdetails.dropoffname;
      this.travelDateInit=this.bookingdetails.traveldate;
      this.returnDateInit=this.bookingdetails.returndate;
      this.fromPlaceholder = this.bookingdetails.pickupname;
      this.toPlaceholder = this.bookingdetails.dropoffname;
      this.returning=this.bookingdetails.returning;
      this.serviceDepature.changeValue(this.pickUpInit);
      this.serviceDestination.changeValue(this.dropOffInit);
      if(this.bookingdetails.returning){
        this.returnPickUpInit=this.bookingdetails.dropoffname;
        this.returnDropOffInit=this.bookingdetails.pickupname;
      }
      this.searchBuses(JSON.parse(Base64.decode(message)))
    });


    this.mixpanelService.track('PageView', {
      pageName: 'Schedules page',
      source: 'buupassSite',
      role: 'customer',
    });



  }
  ngAfterViewInit(): void {
    // Hack: Scrolls to top of Page after page view initialized
    let top = document.getElementById('tophome');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
     setTimeout(()=>{
       this.reactiveForm.patchValue({traveldate:formatDate(this.bookingdetails.traveldate,"dd-MM-yyyy","en-KE"),pickup: this.pickUpInit,dropoff: this.dropOffInit})
       if(this.bookingdetails.returning){
         this.reactiveForm.patchValue({returndate:new Date(formatDate(this.bookingdetails.returndate,"yyyy-MM-dd","en-KE")) })
       }
     },0)

    this.checkDates();
  }


  interChangeValues(pickup,dropoff){
    this.fromPlaceholder = pickup;
    this.toPlaceholder = dropoff;
    this.serviceDepature.changeValue(pickup);
    this.serviceDestination.changeValue(dropoff);

    setTimeout(()=>{
      this.reactiveForm.patchValue({traveldate:formatDate(this.travelDateInit,"dd-MM-yyyy","en-KE")})
    },0)

  }
  checkDates=() =>{
    let traveldate = formatDate(this.travelDateInit,"yyyy-MM-dd","en-KE");
    let today = formatDate(this.today,"yyyy-MM-dd","en-KE");
    var date = new Date(traveldate);
    this.beforeyesterday=new Date(date.setDate(date.getDate()-2));
    this.yesterday=new Date(date.setDate(date.getDate()+1));
    this.tomorrow =new Date(date.setDate(date.getDate()+2))
    this.aftertomorrow =new Date(date.setDate(date.getDate()+2));
      if(traveldate == today)
          return true;
      else
        return false;
  }
  changeDate=(selected) =>{
    this.travelDateInit=formatDate(selected,"yyyy-MM-dd","en-KE");
    this.reactiveForm.patchValue({traveldate:formatDate(this.travelDateInit,"dd-MM-yyyy","en-KE"),pickup: this.pickUpInit,dropoff: this.dropOffInit})
    this.checkDates();
    let webformdata={
      'pickup' : this.pickUpInit,
      'dropoff' : this.dropOffInit,
      'pickupname' : this.pickUpInit,
      'dropoffname' :this.dropOffInit,
      'traveldate' :this.travelDateInit,
      'returndate' :this.returnDateInit,
    }
    let message =Base64.encode(JSON.stringify(webformdata));
    localStorage.setItem('search', message);
    this.searchBuses(webformdata);
  }
  interChange=()=>{
    this.changeI=!this.changeI;

       const dropOff = this.data.find(city => city === this.dropOffInit);
       const pickUp = this.data.find(city => city === this.pickUpInit);
      let webformdata={
        'pickup' : dropOff,
        'dropoff' : pickUp,
        'pickupname' : dropOff,
        'dropoffname' :pickUp,
        'traveldate' :this.travelDateInit,
        'returndate' :this.returnDateInit,
        'returning' : this.returning
      }

      let message =Base64.encode(JSON.stringify(webformdata));
      localStorage.setItem('search', message);

      this.interChangeValues(dropOff,pickUp);
      this.searchBuses(webformdata);

  }
  private loadDestinations= () => {
    this.homeservice.getDestinations().subscribe(
      response => {
        let list: string[] = [];
        this.res = response;
          this.res.data.all_cities.forEach(element => {
              list.push(element.name);
          });
        this.data=list;
        this.types=this.res.data;
        this.destinationLoading=false;
      },
      error => {
        return EMPTY;
      }
    );
  }
  backHome(){
    this.router.navigateByUrl('/home');
  }
  forMatThisData = (data) => {
    return formatDate(data,"dddd dS, M","en-KE");
  }
  submitWebForm() {
    this.submitted = true;
 
     let value = this.reactiveForm.value;
     var pattern = /^(\d{1,2})-(\d{1,2})-(\d{4})$/;
     if (typeof value.traveldate === 'string' || value.traveldate instanceof String){
       var arrayDate = value.traveldate.match(pattern);
       var dt = new Date(arrayDate[3], arrayDate[2] - 1, arrayDate[1]);
       var traveldate = formatDate(dt,"yyyy-MM-dd","en-KE");
     }else{
       var traveldate = formatDate(value.traveldate,"yyyy-MM-dd","en-KE");

     }
     var returndate='';
     if(this.returning){
        this.returning=true;
            if (typeof value.traveldate === 'string' || value.traveldate instanceof String){
              var ptrn = /^(\d{1,2})-(\d{1,2})-(\d{4})$/;
              var returndate = formatDate(value.returndate,"yyyy-MM-dd","en-KE");
            }else{
              var returndate = formatDate(value.returndate,"yyyy-MM-dd","en-KE");
            }
       value.returndate=returndate;
     }else{
         this.returning=false;
     }

    let webformdata={
      'pickup' : value.pickup,
      'dropoff' : value.dropoff,
      'pickupname' : value.pickup,
      'dropoffname' : value.dropoff,
      'traveldate' : traveldate,
      'returndate' : value.returndate,
      'returning' : this.returning
    }
    let message =Base64.encode(JSON.stringify(webformdata));
    localStorage.setItem('search', message);

    this.searchBuses(webformdata);
  }

  submitMobileForm() {
    this.submitted = true;
     let value = this.reactiveMobileForm.value;
    var traveldate = new Date(formatDate(value.traveldate,"yyyy-MM-dd","en-KE"));
    var returndate = formatDate(value.returndate,"yyyy-MM-dd","en-KE");
    value.traveldate=traveldate;
    let mobileformdata={
      'pickup' : value.pickup,
      'dropoff' : value.dropoff,
      'pickupname' : value.pickup,
      'dropoffname' : value.dropoff,
      'traveldate' : traveldate,
      'returndate' : returndate,
    }
    this.searchBuses(mobileformdata);
  }
  toggleShow(results: any) {
      this.seatsSelected=[];
      this.totalFare=0;
      this.dataLoading=false;
      this.hide_from_ui = 0;
      this.hide_from_ui_shuttle = 0;
      this.isBusSelected = false
      $("#mySidenavDropoff").width('0px');
      $("#mySidenav").width('0px');
      $(".fadeMe").hide();
      $("#mySidenavShuttle").width('0px');
    }
    selectSeats(results: any) {
      this.selectedBus=results;
      this.selectedBusStatus=true;
      this.dataLoading =true;
      this.hide_from_ui = results.id;
       $("#mySidenav").width('380px');
       $(".fadeMe").show();
      this.seatsSelected=[];
      this.seatData = [];
      this.seatMobile=[];
      this.totalFare=0;
      this.seats=[];
      let traveldate=formatDate(this.travelDateInit,"d-M-yyyy","en-KE");
      let query ={
        'fleet_registration_id': results.id,
        'date': traveldate,
        'start_point': results.origin_city_id,
        'end_point': results.destination_city_id,
        'alias': results.operator.alias,
        'rsc_id': results.route_schedule_id,
        'bus_id': results.id,
        'id': results.id,
        'no_of_seats': results.number_of_available_seats,
        'fare': results.fare,
        'leaving_from': results.from,
        'going_to': results.to,
      };

      moengage.track_event('Bus Select', {
        'SelectedBusOperator': results.operator.alias,
        'Fare':parseInt(results.fare),
        'RouteDepatureCity': results.from,
        'RouteDestinationCity': results.to,
        'AvailableSeats': results.number_of_available_seats,
        'BusId': results.id,
        'TravelDate': traveldate,
        // Add more relevant details if needed
      });

        this.getAvailableSeats(query);
      }

      selectDropoff(results: any) {
        // this.selectedBus=results;
        // // this.selectedBusStatus=true;
        // this.dataLoading =true;
        // this.hide_from_ui = results.id;
         $("#mySidenavDropoff").width('380px');
         $(".fadeMe").show();

         $("#mySidenav").width('0px');
         $("#mySidenavShuttle").width('0px');

      }

      

      getAvailableSeats(query){
        this.seatErrors=false;
        this.service.getSeats(query).subscribe(
        response => {
          this.seatErrors=false;
          this.seatRes = response;
          if(query.alias == 'moderncoast'){
            if(this.seatRes.data.available_seats.online_discount > 0){
              this.IsDiscount=true
              this.discountAmount=this.seatRes.data.available_seats.online_discount
            }
            
          }
          this.availableSeats =this.seatRes.data.available_seats.available_seat_ids;
          this.seats = this.seatRes.data.available_seats.seats;
          this.booked_seat_session_id =this.seatRes.data.available_seats.booked_seat_session_id;
      
          for(let i=0;i<this.seats.length;i++)
          {
            let iseats =this.seats[i];
            let s =5;
            for(let j=0;j< iseats.length;j++){
                var temp = {s:s,row:i,id: iseats[j].id, type:iseats[j].type, status:iseats[j].status,space_type:iseats[j].space_type,fare:iseats[j].fare};
                this.seatData.push(temp);
                s--;
            }
            let m=5;
            for(let j=0;j< iseats.length;j++){
              var mobiletemp = {m:m,row:i,id: iseats[j].id, type:iseats[j].type, status:iseats[j].status,space_type:iseats[j].space_type,fare:iseats[j].fare};
              this.seatMobile.push(mobiletemp);
              m++;
           }
              this.seatData.sort(function (a, b) {
                return a.s - b.s;
              });
              this.seatMobile.sort(function (a, b) {
                return a.m - b.m;
              });
              this.dataLoading=false;


          }
          const groupBy = key => array =>
          array.reduce((objectsByKeyValue:any, obj:any) => {
            const value = obj[key];
            objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
            return objectsByKeyValue;
          }, {});

          const groupByVal = groupBy('s');
          const groupByValMobile = groupBy('row');
          this.seatData = Object.values(groupByVal(this.seatData));
          this.seatMobile = Object.values(groupByValMobile(this.seatMobile));

        },
        error => {
          this.seatErrors=true;
          this.dataLoading =false;

          return EMPTY;

        }
      );
      }

     seatSelect(event:any,result:any,id:number,space_type:string,fare:any){
     
      let seatFare:any;
       let seatsPrices =result.seats_types;
       let seatPrice ='0';
       let seatCurrency ='KSH';
       if(result.operator.alias == 'moderncoast'){
          seatPrice=this.availableSeats[id];
       }else if(result.operator.alias == 'mashpoa'){
        for(let i=0; i<seatsPrices.length; i++){
          if(space_type == seatsPrices[i].alias){
            seatPrice=seatsPrices[i].fare;
            seatCurrency=seatsPrices[i].currency;
          }
        }
     }else{
        for(let i=0; i<seatsPrices.length; i++){
      
          if(space_type == seatsPrices[i].alias || (space_type =="business" &&  seatsPrices[i].alias =="business")){
              seatPrice=seatsPrices[i].fare;
              seatCurrency=seatsPrices[i].currency;
          }else{
            if(seatsPrices[i].fare > 0){
              seatPrice=seatsPrices[i].fare;
              seatCurrency=seatsPrices[i].currency;
            }
          }
      }
       }
      if(event.srcElement.parentElement.classList.contains('unavailable')){
      }else{
        if(this.seatsSelected.some(e => e.id === id)){

          if(event.srcElement.classList.contains('single-seat')){
            event.srcElement.classList.remove("selected");
          }
          this.totalFare=this.totalFare-parseInt(seatPrice);
          this.fareCurrency = seatCurrency;
          this.seatsSelected = this.seatsSelected.filter(item => item.id !== id);
          event.srcElement.parentElement.classList.remove('selected');
        }else{
          if(event.srcElement.classList.contains('single-seat')){
            event.srcElement.classList.add("selected");
          }
          this.totalFare=this.totalFare+parseInt(seatPrice);
          this.fareCurrency = seatCurrency;
          // this.seatsSelected.push({id:id,space_type:space_type});
           seatFare=fare[space_type]
          if(result.operator.alias == 'easycoach' && seatFare === undefined ){
            seatFare = fare.normal;
           }
          this.seatsSelected.push({id:id,space_type:space_type,fare:seatFare});
          event.srcElement.parentElement.classList.add('selected');
          if(this.return_schedule){
            this.busSelected=result;
            this.currentBusId=result.id;
            this.hide_from_ui=0;
            this.busOperatorSelected=result.operator.alias;
            this.isBusSelected =true;
          }
        }

        moengage.track_event('Seat Selection', {
          'SelectedSeatNumber': id,
          'SeatType': space_type,
          'BusOperator':result.operator.alias,
          'SeatPrice':parseInt(seatFare)
          // Add more relevant details if needed
        });

      }


      this.mixpanelService.track('SeatSelect', {
        seatNumber: id,
        operator: result.operator.alias,
        source: 'buupassSite',
        role: 'customer',

      });




     }
     refreashSeats(){
      this.dataLoading=true;
      let webformdata={
        'pickup' : this.pickUpInit,
        'dropoff' : this.dropOffInit,
        'pickupname' : this.pickUpInit,
        'dropoffname' :this.dropOffInit,
        'traveldate' :this.travelDateInit,
      }
      this.sendRefreash(webformdata)


    }

    // Refresh bus seats
    sendRefreash(search:any){
      search.traveldate=formatDate(search.traveldate,"yyyy-MM-dd","en-KE");
      this.pickUpInit = search.pickupname;
      this.dropOffInit = search.dropoffname;
      this.service.onSearch(search).subscribe(
        response => {
          this.dataLoading=false;
          this.res = response;
          this.results = this.res.data;
          if( this.res.data ) {
            this.pickup =  this.pickUpInit;
            this.dropoff =  this.dropOffInit;
          }
        },
        error => {
          return EMPTY;
        }
      );
    }
    get f() { return this.reactiveForm.controls; }
     // pickupanddropoff
    get fpickup() { return this.pickupForm.controls; }
    
    //book shuttle
    bookShuttle(result:any){
      this.selectedBus=result;
      this.hide_from_ui_shuttle = result.trip_id;
      $("#mySidenavShuttle").width('380px');
      $(".fadeMe").show();
      this.selectedBusStatus=true;

      const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;
        let seatsPrices =result.seats_types;
        let seatPrice ='0';
        for(let i=0; i<seatsPrices.length; i++){
             if(seatsPrices[i].fare > 0){
               seatPrice=seatsPrices[i].fare;
             }
        }
        this.totalFare=parseInt(seatPrice);
        this.busSelected=result;
        this.currentBusId=result.id;
        this.busOperatorSelected=result.operator.alias;
        this.isBusSelected =true;

      //TODO implement the shuttle process
      this.formdataTravel={
        'result':result,
        'fare': result.fare,
        'title': result.from + ' to ' + result.to,
        'total_fare':this.totalFare,
        "search_details": this.bookingdetails,
        'pickup' : result.boarding_points.length,
        'dropoff' :result.dropoff_points,
        'totalfare' : this.totalFare,
        'seatsSelected' : this.seatsSelected
      }

      let paytravel=Base64.encode(JSON.stringify(this.formdataTravel));
        let syncData={
          'travel':paytravel,
          'return':'',
          'returning':false
        }
    }

    selectPassOption(id: number,selectedBus) {
      let seatsPrices =selectedBus.seats_types;
      let seatPrice ='0';
      for(let i=0; i<seatsPrices.length; i++){
           if(seatsPrices[i].fare > 0){
             seatPrice=seatsPrices[i].fare;
           }
     }
       //getted from event

       this.seatsSelected=[];
       this.selectedOptionPassengers=id
       this.totalFare=parseInt(this.selectedOptionPassengers) * parseInt(seatPrice)

       for(let i=0;i<this.selectedOptionPassengers;i++)
       {
           const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;
           this.seatsSelected.push({id:random(1, 23),space_type:'business'});
       }

     }
    // Submit the seat selection for payment processing
    submitForm(result:any) {
      this.submitted = false;

      if (result.dropoff_points.length <1 ) result.dropoff_points.push(this.dropOffInit);
      if (result.boarding_points.length <1 ) result.boarding_points.push(this.pickUpInit);
      if(this.isReturnTicket && !this.isReturnTicketSelected){
        this.isReturnTicketSelected=true;
        let resultsData:any;
        let resultSeats:any;
        resultsData=result;
      
        resultSeats=this.seatsSelected;
        // var momentObj = moment(resultsData.departure_date, 'MM-DD-YYYY');
        if(resultsData.operator.alias =='easycoach'){
          var momentObj = moment(resultsData.departure_date, 'MM-DD-YYYY');
          resultsData.departure_date = momentObj.format('DD/MM/YYYY'); // 2016-07-15
        }else if (resultsData.is_shuttle){
          var momentObj = moment(resultsData.departure_date, 'YYYY-MM-DD');
          resultsData.departure_date = momentObj.format('YYYY-MM-DD'); // 2016-07-15
        }
        else{
          resultsData.departure_date = this.finalReturnDateInit;
        }
      // pickupanddropoff
        if(resultsData.operator.alias =='mashpoa'){

          this.submittedMashPoa = true;
          if(!this.pickupForm.valid){
            return
          }
          let pickupreturn:any;
          let fvalues = this.pickupForm.value;
          pickupreturn = fvalues;
          $("#mySidenav").width('0px');
          $(".fadeMe").hide();
       
          this.pickupreturnlocation = pickupreturn.pickupLocation
          this.dropoffreturnlocation = pickupreturn.dropoffLocation
        }else{
          $("#mySidenav").width('0px');
          $(".fadeMe").hide();
          this.pickupreturnlocation = this.fromPlaceholder 
          this.dropoffreturnlocation = this.toPlaceholder
        }

        this.formdataReturn={
          'result':resultsData,
          'fare': resultsData.fare,
          'title': resultsData.from + ' to ' + resultsData.to,
          'total_fare':this.totalFare,
          'fare_currency':this.fareCurrency,
          "search_details": this.bookingdetails,
          'search_from' : this.fromPlaceholder,
          'search_to' :this.toPlaceholder,
          'pickup' : resultsData.boarding_points.length,
          'dropoff' :resultsData.dropoff_points,
          'pickup_location':this.pickupreturnlocation,
          'dropoff_location':this.dropoffreturnlocation,
          'totalfare' : this.totalFare,
          'seatsSelected' : resultSeats,
          'booking_session_id':this.booked_seat_session_id,
          'insurance':this.insurance,
          'insurance_operators':this.insurance_operators
        }
      }else{
        let initialData:any;
        initialData=result;
       
         // pickupanddropoff
        if(initialData.operator.alias =='mashpoa'){
         
          if(!this.pickupForm.valid){
            return
          }
          if(this.returning){
            this.submittedMashPoa = false;
            $("#mySidenavDropoff").width('0px');
            $("#mySidenav").width('0px');
            $(".fadeMe").hide();
            this.pickupForm.reset();
          }else{
            this.submittedMashPoa = true;
            $("#mySidenav").width('0px');
            $(".fadeMe").hide();
          }
      

          let fvalues = this.pickupForm.value;
          this.pickuplocation = fvalues.pickupLocation
          this.dropofflocation = fvalues.dropoffLocation
        }else{
          $("#mySidenav").width('0px');
          $(".fadeMe").hide();
          this.pickuplocation = initialData.from 
          this.dropofflocation = initialData.to
        }


        //initialData.departure_date = this.finalTravelDate;
        if(initialData.operator.alias =='easycoach'){
          var momentObj = moment(initialData.departure_date, 'MM-DD-YYYY');
          initialData.departure_date = momentObj.format('MM/DD/YYYY'); // 2016-07-15
        }else if (initialData.is_shuttle){
          var momentObj = moment(initialData.departure_date, 'YYYY-MM-DD');
          initialData.departure_date = momentObj.format('YYYY-MM-DD'); // 2016-07-15
        }else{
          initialData.departure_date = this.finalTravelDate;
        }
        this.formdataTravel={
          'result':initialData,
          'fare': initialData.fare,
          'title': initialData.from + ' to ' + initialData.to,
          'total_fare':this.totalFare,
          'fare_currency':this.fareCurrency,
          "search_details": this.bookingdetails,
          'pickup' : initialData.boarding_points.length,
          'dropoff' :initialData.dropoff_points,
          'search_from' : this.fromPlaceholder,
          'search_to' :this.toPlaceholder,
          'pickup_location':this.pickuplocation,
          'dropoff_location':this.dropofflocation,
          'totalfare' : this.totalFare,
          'seatsSelected' : this.seatsSelected,
          'booking_session_id':this.booked_seat_session_id,
          'insurance':this.insurance,
          'insurance_operators':this.insurance_operators

        }
      }
      //check if the trip is one way
      if(this.bookingdetails.returning && !this.isReturnTicket){
        this.interChangeValues(this.bookingdetails.dropoff,this.bookingdetails.pickup);
        let returnsearch={
          'pickup' : this.bookingdetails.dropoff,
          'dropoff' : this.bookingdetails.pickup,
          'pickupname' : this.bookingdetails.dropoff,
          'dropoffname' :this.bookingdetails.pickup,
          'traveldate' :this.bookingdetails.returndate,
          'returndate' :'',
        }
        this.isReturnTicket=true;
        this.interChangeValues(this.bookingdetails.dropoff,this.bookingdetails.pickup);
        this.searchBuses(returnsearch);
      } else if(this.bookingdetails.returning && this.isReturnTicket){
        let paytravel=Base64.encode(JSON.stringify(this.formdataTravel));
        let payreturn=Base64.encode(JSON.stringify(this.formdataReturn));
        let syncData={
            'travel':paytravel,
            'return':payreturn,
            'returning':true
        }
        let pay=Base64.encode(JSON.stringify(syncData));
        let paymessage=Base64.encode("Opps sory mate!");
        this.dataService.changePaymentMessage(pay);
        this.selectedBusStatus = false;
        this.router.navigate(['/payments', paymessage])
      } else {
        let paytravel=Base64.encode(JSON.stringify(this.formdataTravel));
          let syncData={
            'travel':paytravel,
            'return':'',
            'returning':false
          }
        let pay=Base64.encode(JSON.stringify(syncData));
        let paymessage=Base64.encode("Opps sory mate!");
        this.dataService.changePaymentMessage(pay);


        this.selectedBusStatus = false;
        this.router.navigate(['/payments', paymessage])
      }


      this.mixpanelService.track('BusSelected', {
        operator: result.operator.alias,
        pickup : this.fromPlaceholder,
        dropoff :this.toPlaceholder,
        fare: this.formdataTravel.total_fare,
        source: 'buupassSite',
        role: 'customer',
      });

    

    }
  searchBuses(search:any){
    let message=Base64.encode(JSON.stringify(search));

    moengage.track_event('Bus ticket search', {
      'DepartureCity': search.pickup,
      'ArrivalCity': search.dropoff,
      'DepartureDate': search.traveldate
      // Add more relevant details if needed
    });
    if(this.isReturnTicket){
      let newReturn = [];
        this.results = this.return_schedule;
      for(let i=0;i<this.results.length;i++)
      {
          if(this.results[i].operator.alias == this.busOperatorSelected){
            newReturn.push(this.results[i]);
          }
      }
      this.results=newReturn
      if(this.results.length ==0 && this.submitted){
        this.isEmptyReturn=true;
          this.submitted = false;
          this.results=[];
          this.isResultsLoading=true;
          this.travelDateInit=search.traveldate;
          search.traveldate=formatDate(search.traveldate,"dd/MM/yyyy","en-KE");
          if(this.returning){
            search.returndate=formatDate(search.returndate,"dd/MM/yyyy","en-KE");
          }
          this.pickUpInit = search.pickupname;
          this.dropOffInit = search.dropoffname;
          this.finalTravelDate =search.traveldate;
          this.finalReturnDateInit =search.returndate
          search.returning=this.returning;
          this.service.onSearch(search).subscribe(
            response => {
              this.isResultsLoading=false;
              this.res = response;
              this.insurance = this.res.data.insurance.data;
              this.insurance_operators = this.res.data.insurance.allowed_operators;
              if(this.returning){
                this.results = this.res.data.initial_trip;
                //this.results = this.res.data.return_trip;
                for(let i=0;i<this.results.length;i++)
                {
                    if(this.results[i].operator.alias == this.busOperatorSelected){
                      newReturn.push(this.results[i]);
                    }
                }
                this.results=newReturn
              }else{
                
                let newResults=[];
                let resultsData = this.res.data.schedule;

                for(let i=0;i < resultsData.length;i++)
                {
                    if( resultsData[i].operator.alias != 'easycoach-none'){
                      newResults.push(resultsData[i]);
                    }
                }
                this.results=newResults;
                
              }
              if( this.res.data ) {
                this.pickup =  this.pickUpInit;
                this.dropoff =  this.dropOffInit;
              }
            },
             error => {
               return EMPTY;
            }
          );
      }

    }else{
      localStorage.setItem('search-buupass', message);
    this.results=[];
    this.isResultsLoading=true;
    this.travelDateInit=search.traveldate;
    if(search.traveldate != undefined)
        search.traveldate=formatDate(search.traveldate,"dd/MM/yyyy","en-KE");
    if(this.returning){
      search.returndate=formatDate(search.returndate,"dd/MM/yyyy","en-KE");
    }
    this.pickUpInit = search.pickupname;
    this.dropOffInit = search.dropoffname;
    search.returning=this.returning;
    this.finalTravelDate =search.traveldate;
    this.finalReturnDateInit =search.returndate
    this.interChangeValues(search.pickupname,search.dropoffname);
    this.service.onSearch(search).subscribe(
      response => {
        this.isResultsLoading=false;
        this.res = response;
        this.insurance = this.res.data.insurance.data;
        this.insurance_operators = this.res.data.insurance.allowed_operators;

        if(this.returning){
          let newResults=[];
          let resultsData = this.res.data.initial_trip;
          for(let i=0;i < resultsData.length;i++)
          {
              if( resultsData[i].operator.alias != 'easycoach-none'){
                newResults.push(resultsData[i]);
              }
          }
          this.results=newResults;
          this.return_schedule = this.res.data.return_trip;
        }else{
          let newResults=[];
          let resultsData = this.res.data.schedule;
          for(let i=0;i < resultsData.length;i++)
          {
              if( resultsData[i].operator.alias != 'easycoach-none'){
                newResults.push(resultsData[i]);
              }
          }
          this.results=newResults;
        }
        if( this.res.data ) {
          this.pickup =  this.pickUpInit;
          this.dropoff =  this.dropOffInit;
        }

      },
      error => {
        return EMPTY;
      }
    );
    }
  }

  trackByBooking(result:any) {
    return result.id;
 }

 acClick(){
   if(this.isAcClicked){
     this.isAcClicked=false;
   }else{
     this.isAcClicked=true;
   }

 }
isWaterClicked(){

  if(this.isWater){
    this.isWater=false;
  }else{
    this.isWater=true;
  }

}





}
